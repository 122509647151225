import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import GadulaLogoImg from '../../../assets/img/gadula-logo.png';
import LogoImg from '../../../assets/img/logo.png';
import Anchor from '../../../components/shared/anchor';
import List, { ListItem } from '../../../components/shared/list';
import Text from '../../../components/shared/text';
import Social from '../../../components/socials/layout-one';
import styles from './footer.module.scss';
import {
  AddressWidget,
  FooterBottom,
  FooterTop,
  FooterWidget,
  FooterWrap
} from './footer.stc';

const Footer = props => {
  const FooterData = useStaticQuery(graphql`
    query FooterDataQuery {
      site {
        siteMetadata {
          contact {
            social {
              facebook
              instagram
              linkedin
              twitter
              youtube
            }
          }
        }
      }
    }
  `);
  const { social } = FooterData.site.siteMetadata.contact;
  const {
    footerMenuOne,
    address: { addressAnchor, addressText },
    socialStyle,
    footerMenuTwo,
    copyright,
    widgetStyle: { logoWidget, menuOneWidget, socialWidget, menuTwoWidget }
  } = props;
  return (
    <FooterWrap>
      <Container>
        <FooterTop>
          <Row>
            <Col md={2} lg={2}>
              <FooterWidget {...logoWidget}>
                <img
                  src={LogoImg}
                  alt="Footer Logo"
                  className={styles.footerLogo}
                />
              </FooterWidget>
            </Col>
            <Col md={{ size: 3, offset: 2 }} lg={{ size: 2, offset: 3 }}>
              <FooterWidget {...menuOneWidget}>
                <List {...footerMenuOne}>
                  <ListItem>
                    <Anchor path="/faq">NAJCZĘŚCIEJ ZADAWANE PYTANIA</Anchor>
                  </ListItem>
                  <ListItem>
                    <Anchor path="/search?query=iwona%20libich">
                      ARTYKUŁY
                    </Anchor>
                  </ListItem>
                </List>
              </FooterWidget>
            </Col>
            <Col md={{ size: 4, offset: 1 }} lg={{ size: 3, offset: 1 }}>
              <FooterWidget>
                <AddressWidget>
                  <img
                    src={GadulaLogoImg}
                    alt="Gaduła Logo"
                    className={styles.gadulaLogo}
                  />
                  <Anchor {...addressAnchor} path="tel:+447508609703">
                    07508 609 703
                  </Anchor>
                  <Anchor
                    {...addressAnchor}
                    path="mailto:iwona.libich@polskilogopeda.co.uk"
                  >
                    iwona.libich@polskilogopeda.co.uk
                  </Anchor>
                  <Text {...addressText}>Watford WD25, UK</Text>
                </AddressWidget>
              </FooterWidget>
            </Col>
          </Row>
        </FooterTop>
        <FooterBottom>
          <Row className="align-items-end">
            <Col md={3} xl={3}>
              <FooterWidget {...socialWidget}>
                <Social social={social} {...socialStyle} />
              </FooterWidget>
            </Col>
            <Col
              md={{ size: 3, offset: 1 }}
              lg={{ size: 2, offset: 2 }}
              xl={{ size: 2, offset: 2 }}
            >
              <FooterWidget {...menuTwoWidget}>
                <List {...footerMenuTwo}>
                  <ListItem>
                    <Anchor path="/">Strona główna</Anchor>
                  </ListItem>
                  <ListItem>
                    <Anchor path="/diagnoza-i-terapia">
                      Diagnoza i terapia
                    </Anchor>
                  </ListItem>
                  <ListItem>
                    <Anchor path="/kursy-i-szkolenia">Cennik</Anchor>
                  </ListItem>
                  <ListItem>
                    <Anchor path="/kursy-i-szkolenia">Kursy i szkolenia</Anchor>
                  </ListItem>
                  <ListItem>
                    <Anchor path="/o-mnie">O mnie</Anchor>
                  </ListItem>
                  <ListItem>
                    <Anchor path="/kontakt">Kontakt</Anchor>
                  </ListItem>
                </List>
              </FooterWidget>
            </Col>
            <Col md={{ size: 4, offset: 1 }} lg={{ size: 4, offset: 1 }}>
              <FooterWidget>
                <Text {...copyright}>
                  PolskiLogopeda.co.uk © 2011 - {new Date().getFullYear()}
                  <br />
                  <br />
                  Wszystkie prawa autorskie do opublikowanych materiałów
                  zastrzeżone.
                  <br />
                  <br />
                </Text>
                <Text {...copyright} className={styles.webmaster}>
                  Projekt i budowa witryny:
                  <br />
                  <a
                    href="https://tomaszlibich.com"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Tomasz Libich
                  </a>{' '}
                  -{' '}
                  <a
                    href="https://libtom.com"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Libtom Software Ltd.
                  </a>
                </Text>
              </FooterWidget>
            </Col>
          </Row>
        </FooterBottom>
      </Container>
    </FooterWrap>
  );
};

Footer.defaultProps = {
  footerMenuOne: {
    color: '#000000',
    pb: '14px',
    textTransform: 'uppercase',
    fontWeight: 500
  },
  widgetStyle: {
    logoWidget: {
      mb: ['33px', null, null, 0]
    },
    menuOneWidget: {
      mb: ['29px', null, null, 0]
    },
    socialWidget: {
      mb: ['34px', null, null, 0]
    },
    menuTwoWidget: {
      mb: ['35px', null, null, 0]
    }
  },
  address: {
    addressAnchor: {
      textTransform: 'uppercase',
      display: 'block',
      color: '#000000',
      fontWeight: 500,
      mb: '13px'
    },
    addressText: {
      fontWeight: 400,
      lineHeight: 2,
      mt: ['20px', null, null, null, '40px'],
      color: '#000000'
    }
  },
  socialStyle: {
    pr: ['15px', null, null, '12px', '15px', '20px'],
    icon: {
      width: '18px',
      height: '18px'
    }
  },
  footerMenuTwo: {
    color: '#000000',
    pb: '14px',
    fontWeight: 400
  },
  copyright: {
    borderTopWidth: '1px',
    borderTopStyle: 'solid',
    borderTopColor: 'borderColor',
    pt: '25px'
  }
};

export default Footer;
