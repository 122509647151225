import '@fontsource/dancing-script';
import '@fontsource/marck-script';
import '@fontsource/montserrat';
import 'bootstrap/dist/css/bootstrap.min.css';

import { GlobalCSS } from '../../assets/css/style';
import PropTypes from 'prop-types';
import React from 'react';
import { ThemeProvider } from 'styled-components';
import theme from '../../theme';

const Layout = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <div className="wrapper">
        <GlobalCSS />
        {children}
      </div>
    </ThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
