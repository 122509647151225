import React from 'react';
import Anchor from '../shared/anchor';
import styles from './index.module.scss';

const Logo = () => {
  return (
    <div className={styles.logo}>
      <Anchor path="/" className={styles.text}>
        <span>Polski Logopeda w Londynie</span>
      </Anchor>
    </div>
  );
};

Logo.propTypes = {};

export default Logo;
